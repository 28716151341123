<template>
    <div id="orderInfo">
        <div class="l-boxs box-p-5"></div>
        <express-info-v1
                v-if="stepInfo && stepInfo.step && stepInfo.step.length > 0"
                :stepInfo="stepInfo"
        ></express-info-v1>
        <div class="card box-m-t-10 box-p-l-20 box-p-r-20">
            <cellV2
                    class="cell cell_split"
                    v-if="stepInfo.fuzeren.type > 0"
                    :type="2"
                    leftText="负责人"
                    :leftIcon="require('@/static/image/icons/user.png')"
                    rightText="联系他"
                    :rightIcon="require('@/static/image/icons/mobile.png')"
                    @onRight="contact"
            >
                <div class="cell_content">{{ stepInfo.fuzeren.name }}</div>
            </cellV2>
            <cellV2
                    v-if="stepInfo.fuzeren.type == 2 && orderInfo.status != 3"
                    class="cell cell_split"
                    :type="2"
                    leftText="提货时间"
                    :leftIcon="require('@/static/image/icons/shop.png')"
                    rightText="自提码"
                    :rightIcon="require('@/static/image/icons/code.png')"
            >
                <div class="cell_content">{{ workTime }}</div>
            </cellV2>
            <div class="l-flexv box-p-b-16" v-if="stepInfo.fuzeren.type == 2">
                <cellV2
                        class="cell"
                        :type="2"
                        leftText="自提点"
                        :leftIcon="require('@/static/image/icons/watch.png')"
                        rightText="导航"
                        :rightIcon="require('@/static/image/icons/fly.png')"
                        @onRight="goMap"
                >
                    <div class="cell_content">
                        {{ stepInfo.fuzeren.site_name }}
                    </div>
                </cellV2>
                <div class="description">
                    {{ stepInfo.fuzeren.address }}
                </div>
            </div>
            <div></div>
        </div>
        <div class="card box-m-t-10 card-p10-20">
            <cellV2 class="cell cell_split" :type="5" leftText="联系人">
                <div class="cell_content">{{ expressInfo.name }}</div>
            </cellV2>
            <template v-if="show_refund">
                <cellV2
                        class="cell cell_split"
                        :type="2"
                        leftText="联系电话"
                        leftIcon=""
                        @onRight="contactCustom"
                        rightText="联系他"
                        :rightIcon="require('@/static/image/icons/mobile.png')"
                        :leftIcon="require('@/static/image/icons/user.png')"
                >
                    <div class="cell_content">{{ expressInfo.mobile }}</div>
                </cellV2>
            </template>
            <template v-else>
                <cellV2 class="cell cell_split" :type="5" leftText="联系电话">
                    <div class="cell_content">{{ expressInfo.mobile }}</div>
                </cellV2>
            </template>

            <cellV2 class="cell" :type="5" leftText="收货地点">
                <div class="cell_content">{{ expressInfo.address }}</div>
            </cellV2>
        </div>
        <goodsList
                v-if="orderInfo.goodList.length > 0"
                class="box-m-t-10"
                :orderInfo="orderInfo"
                :goodsList="orderInfo.goodList"
                @refundMoney="refundMoney"
                :showRefund="show_refund"
        ></goodsList>

        <div class="card box-m-t-10 card-p10-20">
            <cellV2 class="cell cell_split" :type="5" leftText="订单编号">
                <div class="cell_content">{{ orderInfo.order_sn }}</div>
            </cellV2>
            <cellV2 class="cell" :type="5" leftText="创建时间">
                <div class="cell_content">{{ orderInfo.create_time }}</div>
            </cellV2>
        </div>

        <div class="card card-p10-20 box-m-t-10 remark">
            <cellV2 class="cell" :type="5" leftText="备注">
                <div class="cell_content">{{ orderInfo.remark }}</div>
            </cellV2>
        </div>
        <div class="l-boxs box-p-10"></div>
    </div>
</template>

<script>
  import ExpressInfoV1 from '@T/community/orders/expressInfoV1'
  import goodsList from '@T/community/goodsList/goodsListV1'
  import cellV2 from '@T/community/cell/cellV2.vue'

  export default {
    name: 'order-view',
    components: {
      ExpressInfoV1,
      goodsList,
      cellV2,
    },
    data () {
      return {
        orderId: 3431, //订单id 默认为0
        orderSn: '',
        show_refund: 0,
        //订单详情
        orderInfo: {
          id: 0, //订单id
          store_id: 0, //店铺id
          status: 0, //
          create_time: '', //订单创建时间
          order_amount: '0.00', //支付金额
          goods_amount: '0.00', //订单金额
          deduct_amount: '0.00', //减免金额
          order_sn: '', //订单号
          goodList: [], //类型：Array  必有字段  备注：订单列表 //refund_status: -1 没有申请过 0：已经提交了申请 1:已经退款成功 2:退款被拒绝
        },
        //收货人信息
        expressInfo: {
          address: '',
          name: '',
          mobile: '',
        },
        //配送信息
        stepInfo: {
          title: '', //步骤标题
          //时间轴
          step: [
            // {
            //   title: "用户确认收货时间", //提示
            //   time: "2021-11-16 03:49", //时间轴
            // },
          ],
          //负责人信息
          fuzeren: {
            type: 0, //1 是配送员信息 2是站点信息
            name: '', //联系人
            site_name: '', //站点名称
            mobile: '', //电话
            address: '', //地址
            location: '', //经纬度
            am_start_time: '', //上午营业开始时间
            am_end_time: '', //上午营业结束时间
            pm_start_time: '', //下午营业开始时间
            pm_end_time: '', //下午营业结束时间
          },
        },
        //自提码
        extends: {
          id: 0, //工作流程
          pick_up_code: '', //提货码
          pick_up_code_url: '', //提货码图片地址
        },
      }
    },
    created () {
      this.orderSn = this.$route.query?.order_sn
      this.show_refund = (this.$route.query?.show_refund || 0) == 1
      document.title = this.orderSn
      this.getOrderInfo()
    },
    computed: {
      workTime () {
        let person = this.stepInfo.fuzeren
        if (person.am_start_time) {
          return `${person.am_start_time}-${person.am_end_time} ${person.pm_start_time}-${person.pm_end_time}`
        } else {
          return ''
        }
      },
    },
    methods: {
      //申请退款
      refundMoney (e) {
        let goods_id = e.goods_id
        let order_id = this.orderId
        let query = {
          order_id: order_id,
          goods_id: goods_id,
        }
        if (this.$platform.wxsdk.isWechat()) {
          query = encodeURIComponent(JSON.stringify(query))
          this.$platform.wxsdk.wxRoute({
            type: 'redirectTo',
            url: '/web/community/apply-for-refund?query=' + query,
          })
        } else {
          console.log('申请退款', e)
          this.$router.push({
            path: 'apply-for-refund',
            query: query,
          })
        }
      },
      //联系负责人
      contact () {
        console.log('联系负责人')
        let self = this
        this.$dialog({
          title: '温馨提示',
          content: `确定要给${self.stepInfo.fuzeren.mobile}打电话吗？`,
          closeOnClickModal: false, //点击蒙层是否关闭对话框
          closeBtn: false, //显式右上角关闭按钮
          onOkBtn () {
            self.$dialog.closed()
            if (self.$platform.wxsdk.isWechat()) {
              self.$platform.wxsdk.wxRoute({
                type: 'navigateTo',
                url:
                '/web/makePhoneCall/common?mobile=' +
                self.stepInfo.fuzeren.mobile,
              })
            } else {
              window.location.href = 'tel:' + self.stepInfo.fuzeren.mobile
            }
          },
        })
      },
      contactCustom () {
        let self = this
        this.$dialog({
          title: '温馨提示',
          content: `确定要给${self.expressInfo.mobile}打电话吗？`,
          closeOnClickModal: false, //点击蒙层是否关闭对话框
          closeBtn: false, //显式右上角关闭按钮
          onOkBtn () {
            self.$dialog.closed()
            if (self.$platform.wxsdk.isWechat()) {
              self.$platform.wxsdk.wxRoute({
                type: 'navigateTo',
                url:
                '/web/makePhoneCall/common?mobile=' +
                self.expressInfo.mobile,
              })
            } else {
              window.location.href = 'tel:' + self.expressInfo.mobile
            }
          },
        })
      },
      //预览二维码
      showCode () {
        this.$showCodeV1({
          title: '请出示您的提货码', // 标题
          codeUrl: this.extends.pick_up_code_url, //二维码链接地址
          tip: '订单编号：' + this.extends.pick_up_code,
          confirmText: '确定', // 确认文字
          success: () => {
            // 确认执行函数
            console.log('确定')
          },
        })
      },
      //导航
      goMap () {
        let lng_lat = this.stepInfo.fuzeren.location.split(',')
        let lng = Number(lng_lat[1])
        let lat = Number(lng_lat[0])
        let options = {
          lng: lng,
          lat: lat,
          site_name: this.stepInfo.fuzeren.site_name,
          address: this.stepInfo.fuzeren.address,
        }
        options = encodeURIComponent(JSON.stringify(options))
        if (this.$platform.wxsdk.isWechat()) {
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/map/map?query=' + options,
          })
        }
      },
      //获取订单详情
      async getOrderInfo () {
        try {
          let params = {
            order_sn: this.orderSn,
          }
          let query = await this.$api.community.order.getOrderViewInfo(params)
          this.orderInfo = query.data.orderInfo || this.orderInfo
          this.orderInfo.goodList.forEach(item => {
            item.return_exchange = 1
          })
          this.stepInfo = query.data.stepInfo || this.stepInfo
          this.extends = query.data.extends || this.extends
          this.expressInfo = query.data.expressInfo || this.expressInfo
        } catch (error) {
          this.$toast.text(error.message, {
            duration: 1000,
          })
        }
      },
    },
  }
</script>

<style scoped lang="scss">
    #orderInfo {
        display: inline-block;
        width: 100%;
        min-height: 100%;
        background: #f6f6f6;
    }

    @include b(card) {
        box-sizing: border-box;
        background: #fff;
        border-radius: 10px;
        @include m(p20) {
            padding: 20px;
        }
        @include m(p10-20) {
            padding: 10px 20px;
        }
    }

    @include b(cell) {
        height: 44px !important;
        @include e(content) {
            box-sizing: border-box;
            padding-left: 12px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }
        @include e(split) {
            border-bottom: 1px solid #ebebeb;
        }
    }

    @include b(description) {
        width: 250px;
        align-self: flex-end;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #939393;
    }

    .remark {
        font-size: 18px;
        color: red;
    }
</style>
